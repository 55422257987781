
import { defineComponent, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name: 'RedirectPaymentLink',
  setup() {
    const route = useRoute();
    const router = useRouter();

    onMounted(() => {
      router.push({
        name: 'PaymentLink',
        params: { link: route.params.token.toString() },
      });
    });
  },
});
